/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { environment } from '@LIB_UTIL/environments/environment';
import { ReadOnlyGuard } from '@LG_ROOT/app/auth/guards/read-only.guard';
import { AuthGuard } from '@LIB_UTIL/auth/guards/auth.guard';
import { LayoutWrapperComponent } from './shared/components/layout/layout-wrapper.component';
import { NotFound404Component } from '@LIB_UTIL/layout/components/not-found404/not-found404.component';
import { TimezoneResolver } from '@LIB_UTIL/timezone/timezone.resolver';

const defaultRedirect: string = environment.noAuthMode ? 'dashboard' : 'auth';
const notFoundRoute: Route = environment.embeddedMode
  ? { path: 'not-found', component: NotFound404Component }
  : {
    path: 'not-found',
    component: LayoutWrapperComponent,
    children: [
      {
        path: '',
        component: NotFound404Component,
      },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  };

const routes: Routes = [
  // When noAuthMode is active, do not allow the user to visit the auth pages.
  environment.noAuthMode
    ? {
      path: 'auth',
      redirectTo: '/dashboard',
    }
    : {
      path: 'auth',
      loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      resolve: { timezone: TimezoneResolver },
    },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    resolve: { timezone: TimezoneResolver },
    canActivate: [AuthGuard],
  },
  {
    path: 'modules',
    loadChildren: () => import('./modules/modules.module').then(m => m.ModulesModule),
    canActivate: [AuthGuard],
    resolve: { timezone: TimezoneResolver },
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuard, ReadOnlyGuard],
    resolve: { timezone: TimezoneResolver },
  },
  {
    path: 'charts',
    loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule),
    canActivate: [AuthGuard],
    resolve: { timezone: TimezoneResolver },
  },
  {
    path: 'gauges',
    loadChildren: () => import('./gauges/gauges.module').then(m => m.GaugesModule),
    canActivate: [AuthGuard],
    resolve: { timezone: TimezoneResolver },
  },
  {
    path: 'crop-groups',
    loadChildren: () => import('./crop-groups/crop-groups.module').then(m => m.CropGroupsModule),
    canActivate: [AuthGuard],
    resolve: { timezone: TimezoneResolver },
  },
  {
    path: 'folders',
    loadChildren: () => import('./folders/folders.module').then(m => m.FoldersModule),
    canActivate: [AuthGuard, ReadOnlyGuard],
    resolve: { timezone: TimezoneResolver },
  },
  {
    path: 'photos',
    loadChildren: () => import('./photos/photos.module').then(m => m.PhotosModule),
    canActivate: [AuthGuard],
    resolve: { timezone: TimezoneResolver },
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard],
    resolve: { timezone: TimezoneResolver },
  },
  {
    path: 'lca',
    loadChildren: () => import('./lca/lca.module').then(m => m.LcaModule),
    canActivate: [AuthGuard],
    resolve: { timezone: TimezoneResolver },
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
    canActivate: [AuthGuard],
    resolve: { timezone: TimezoneResolver },
  },
  {
    path: 'beurs1',
    redirectTo: '/auth/register-expo/beurs1',
  },
  {
    path: 'beurs2',
    redirectTo: '/auth/register-expo/beurs2',
  },
  {
    path: 'winactie',
    redirectTo: '/auth/register-expo/winactie',
  },
  notFoundRoute,
  {
    path: '',
    redirectTo: defaultRedirect,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
