/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@LIB_UTIL/environments/environment';
import { getCachedTimezone } from '@LIB_UTIL/util/date';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { iSiiVersionEndpoint } from '@LIB_UTIL/timezone/timezone-api.service';
import { getTimezoneOffset } from 'date-fns-tz';
import { getCachedTimezoneOffset, defaultTimezone } from '@LIB_UTIL/util/date/timezone';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

  /**
   * Timezone offset in minutes.
   */
  private timeZoneOffset: number = 0;

  /**
   * Do not attach the custom headers for the url in this list.
   */
  private readonly whiteList: string[] = [iSiiVersionEndpoint];

  /**
   * Fetch the timezone from the sessionStorage.
   */
  constructor(
    private authService: AuthService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    const cachedTimeZoneOffset: string = getCachedTimezoneOffset();
    if (cachedTimeZoneOffset) {
      this.timeZoneOffset = Number(cachedTimeZoneOffset);

    } else {
      const timezone: string = getCachedTimezone() || defaultTimezone;
      this.timeZoneOffset = getTimezoneOffset(timezone) / (60 * 1000);
    }
  }

  /**
   * Set the right headers for the request.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.whiteList.includes(request.url)) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        TimeZoneOffset: `${this.timeZoneOffset}`,
        TwoLetterISOLanguageName: this.locale,
      },
    });

    if (environment.sidHeader && this.authService.sid && this.authService.sessionId) {
      request = request.clone({
        setHeaders: {
          sid: this.authService.sid,
          sessionId: this.authService.sessionId,
        },
      });
    }

    return next.handle(request);
  }
}
